import firebase from 'firebase/app';
import 'firebase/database';
// form
import 'firebase/auth';

const sessionsBaseUrl = 'sessions_qr';
const messagesBaseUrl = 'messages_chat_qr_number';
const phonesBaseUrl = 'phones_qr';
const nameBaseUrl = 'name_qr';
const contactsBaseUrl = 'contacts_sessions_qr_company';

export async function getSessionsByBranchId(companyId) {
  return firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${companyId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function createSession(companyId, data) {
  await firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${companyId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function setFirstQrCode(id, data) {
  await firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${id}/qr`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}
export async function getSessionsByCompanyId(companyId) {
  return firebase
    .database()
    .ref(`/${sessionsBaseUrl}/${companyId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getMessageByChatBySession(companyId, sessionId, chatId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const listenForChangesInConversation = async (companyId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${messagesBaseUrl}/${companyId}/${sessionId}`);

  myTable.on('child_changed', async (snapshot) => {
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);

    // Esperar un breve momento antes de volver a obtener los datos
    setTimeout(async () => {
      const updatedSnapshot = await snapshot.ref.once('value');
      const updatedData = updatedSnapshot.val();

      console.log('Updated data with ack', updatedData);
      callback(updatedData); // Ahora el callback tiene la data actualizada
    }, 250); // Ajusta el delay según sea necesario
  });
};

export const listenForChangesInQrCode = async (id, callback) => {
  const myTable = firebase.database().ref(`/${sessionsBaseUrl}/${id}`);
  myTable.on('child_changed', async (snapshot) => {
    // const data = await getInvoiceReportApiHaciendaById(id, branchID);
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });
};

export async function getPhoneNumberById(id) {
  return firebase
    .database()
    .ref(`/${phonesBaseUrl}/${id}/PhoneNumber`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function getNameById(id) {
  return firebase
    .database()
    .ref(`/${nameBaseUrl}/${id}/Name`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const unsubscribeQr = async (id, callback) => {
  const myTable = firebase.database().ref(`/${sessionsBaseUrl}/${id}`);

  // Para desubscribirte del evento, utiliza la referencia al callback
  myTable.off('child_changed', callback);
};

export const unsubscribeConversation = async (companyId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${messagesBaseUrl}/${companyId}/${sessionId}`);

  // Para desubscribirte del evento, utiliza la referencia al callback
  myTable.off('child_changed', callback);
};

/* Ya se guarda en firebase cuando se toca un chat pero se guardan los msj que ya traían, 
lo ideal sería que junte los que están en firebase con los nuevos que trae con el allMessages del backend,
 no se tienen que repetir e ir en orden.
*/
export async function setMessagesByChatBySession(companyId, data, chatId, sessionId) {
  await firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId.replace('@', '').replace('.', '')}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getConversationsBySession(companyId, sessionId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}`)
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        const conversations = snapshot.val();
        console.log('conversation xd', conversations);
        let conversations2 = {};
        Object.entries(conversations).forEach((el) => {
          conversations2 = {
            ...conversations2,
            [el[0]]: { ...el[1], messages: Object.values(el[1]?.messages || {}) },
          };
        });
        return conversations2;
      }
      return [];
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function setUnreadCountTo0(companyId, sessionId, chatId) {
  await firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId}/unreadCount`)
    .set(0)
    .catch((error) => {
      console.error(error);
    });
}

export async function getUnreadCount(companyId, sessionId, chatId) {
  return firebase
    .database()
    .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${chatId}/unreadCount`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export async function setConversationsBySession(companyId, sessionId, data) {
  data.forEach(async (conversation) => {
    await firebase
      .database()
      .ref(`/${messagesBaseUrl}/${companyId}/${sessionId}/${conversation.to.replace('@', '').replace('.', '')}`)
      .set({ conversation })
      .catch((error) => {
        console.error(error);
      });
  });
}

export async function setFirebaseConversationsBySession(companyId, sessionId, data) {
  data.forEach(async (conversation) => {
    console.log('conversation', conversation);
    console.log('conversation to', conversation?.to);
    await firebase
      .database()
      .collection(messagesBaseUrl)
      .doc(companyId)
      .collection(sessionId)
      .doc(conversation.conversation.to.replace('@', '').replace('.', ''))
      .set({ conversation })
      .catch((error) => {
        console.error(error);
      });
  });
}

async function deleteAllDocumentsForACollection(query) {
  const batch = firebase.firestore().batch();
  const snapshot = await query.get();
  snapshot.docs.forEach((doc) => batch.delete(doc.ref));
  await batch.commit();
}

const deleteAllChatsBySession = async (companyId, sessionId) => {
  const collectionRef = firebase.firestore().collection(messagesBaseUrl).doc(companyId).collection(sessionId);

  const query = collectionRef;

  // Si tienes una gran cantidad de documentos, puedes paginar la eliminación
  // para no exceder el límite de eliminación por lotes de Firestore.
  // Puedes utilizar el método limit() y startAfter() para esto.
  await deleteAllDocumentsForACollection(query);

  // await collectionRef?.delete();
};

export async function setSessionByPhone(companyId, data, phoneNumber, sessionId) {
  await firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(companyId)
    .collection(phoneNumber)
    .doc(sessionId)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export async function getSessionByPhone(companyId, phoneNumber) {
  return firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(companyId)
    .collection(phoneNumber)
    .get()
    .then((snap) => {
      console.log('snap', snap);
      if (snap.docs.length > 0) {
        return snap.docs[0].data().id;
      }
      return '';
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function getPhonesByBranch(companyId) {
  return firebase
    .firestore()
    .collection(phonesBaseUrl)
    .doc(companyId)
    .get()
    .then((snap) => {
      const data = snap.data();
      console.log('snap', snap);
      console.log('data', data);
    })
    .catch((error) => {
      console.error(error);
    });
}

const deleteSessionByPhone = async (companyId, phoneNumber, sessionId) => {
  const docRef = firebase.firestore().collection(phonesBaseUrl).doc(companyId).collection(phoneNumber).doc(sessionId);

  await docRef.delete();
};

export const setChatsToNewSession = async (companyId, oldSessionId, newSessionId, phoneNumber) => {
  // obtener chats
  const chats = await getConversationsBySession(companyId, oldSessionId);
  console.log('chats firebase', chats);

  console.log('newSessionId', newSessionId);
  // setear chats a la nueva sesión
  await setFirebaseConversationsBySession(companyId, newSessionId, chats);

  console.log('seteado');
  // eliminar chats sesión vieja
  await deleteAllChatsBySession(companyId, oldSessionId);
  console.log('eliminado');

  // eliminar apuntador de phone
  await deleteSessionByPhone(companyId, phoneNumber, oldSessionId);
};

export async function getContactsBySessionByBranch(companyId, sessionId) {
  return firebase
    .database()
    .ref(`/${contactsBaseUrl}/${companyId}/${sessionId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

export async function setContactsBySessionByBranch(companyId, sessionId, data) {
  await firebase
    .database()
    .ref(`/${contactsBaseUrl}/${companyId}/${sessionId}`)
    .set(data)
    .catch((error) => {
      console.error(error);
    });
}

export const listenForChangesInContacts = async (companyId, sessionId, callback) => {
  const myTable = firebase.database().ref(`/${contactsBaseUrl}/${companyId}/${sessionId}`);
  myTable.on('child_changed', async (snapshot) => {
    // const data = await getInvoiceReportApiHaciendaById(id, branchID);
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });
};
