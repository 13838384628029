import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PricingPlanCard from './PricingPlanCardcompany';
import useLocales from '../../../hooks/useLocales';

const PlanCards = ({ sx }) => {
  const { translate } = useLocales(); // Mover el hook dentro del componente

  const PLAN_LIST = [
    {
      id: 'basic',
      title: translate('plan.button9'),
      price: '1.00',
      planId: 'P-0T556063P3199313DML2RJLI',
      sx: { background: 'lightgray' },
    },
    {
      id: 'business',
      title: translate('plan.button10'),
      price: '9.45',
      planId: 'P-8RH928197U023162YML2RJ2Y',
      sx: { background: 'lightblue' },
    },
    {
      id: 'premium',
      title: 'Premium',
      price: '95.00',
      planId: 'P-41S15358CA589935LML2RKQI',
      sx: { background: 'lightyellow' },
    },
  ];

  return (
    <Grid container spacing={2} sx={sx}>
      {PLAN_LIST.map((plan, index) => (
        <Grid item xs={12} md={4} key={plan.id}>
          <PricingPlanCard card={plan} index={index} needButton />
        </Grid>
      ))}
    </Grid>
  );
};

PlanCards.propTypes = {
  sx: PropTypes.object,
};

export default PlanCards;
