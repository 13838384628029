const getContact = (chat) => ({
  address: 'CR',
  avatar: chat?.avatar || '/static/user/user.png', // 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg',
  email: chat?.id?.user || chat?.email || '',
  id: chat.id || chat.to || chat.name,
  lastActivity: new Date(chat?.lastMessage?.timestamp * 1000 || null).toISOString(),
  name: chat.userName || chat.name || chat.to,
  phone: chat?.id?.user || chat.phone || chat.to,
  role: 'ux designer',
  status: chat.status || 'online',
  username: chat.to || chat.name,
  from: chat?.id?._serialized || chat.from || chat.to,
});

export const getContactsByNode = (contacts) => {
  const newContacts = [];

  contacts.forEach((contact) => {
    const newContact = {
      address: 'CR',
      avatar: contact.avatar || contact.profilePicUrl || '/static/user/user.png', // 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg',
      email: '',
      id: contact.name || '',
      lastActivity: '',
      name: contact.name || '',
      phone: contact.id.user,
      role: 'ux designer',
      status: 'online',
      username: contact.name || '',
      from: contact.id._serialized,
    };
    newContacts.push(newContact);
  });
  return newContacts;
};

export const getContactsByConversations = (conversations) => {
  const contacts = {};
  conversations.forEach((conversation) => {
    // console.log('chat getContactsByWhatsappJs', conversation);
    const newContact = getContact(conversation);

    // console.log('newContact', newContact);
    contacts[conversation.id] = newContact;
  });

  return contacts;
};

// const filterConversation = (conversations, conversationKey) => {
//   console.log('conversations xd', conversations);
//   return conversations.filter((el) => conversationKey === el.id)[0];
// };

export const getParticipantsFrom0 = (el, sender) => {
  // console.log('el getParticipantsByWhatsappJs', el);
  return [
    {
      avatar: el?.avatar || el?.profilePicUrl || '/static/user/user.png', // 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_15.jpg',
      // id: sender.uid,
      id: sender.id,
      name: `${sender.firstName} ${sender.lastName}`,
      username: `${sender.firstName} ${sender.lastName}`,
    },
    { ...getContact(el) },
  ];
};

export const getConversationByWhatsapp = (messages, id, sender, minimalConversation) => {
  const messagesMinimal = messages.map((el) => ({
    attachments: [],
    body: el?.body || '',
    contentType: 'text',
    createdAt: new Date(el?.timestamp * 1000 || null).toISOString(), // '2023-06-12T07:56:20.699Z',
    id: el.id,
    senderId: el?.fromMe ? sender.id || sender.uid : id,
    from: el.fromMe ? el.id.remote : '',
  }));

  const { participants } = minimalConversation;

  const newConversation = {
    archived: !!minimalConversation.archived,
    id,
    messages: messagesMinimal,
    participants,
    type: 'ONE_TO_ONE',
    unreadCount: 0, //  el.unreadCount
    to: messages[0]?.id?.remote || minimalConversation.to || '',
    from: messages[0]?.fromMe ? messages[0]?.id?.remote : minimalConversation.from || '', // el.id._serialized
  };

  // console.log('newConversation', newConversation);
  return newConversation;
};

export const getConversationsByWhatsapp = (allChats, sender) => {
  const conversations = [];
  // console.log('allChats', allChats);
  allChats.forEach((el) => {
    // console.log(`indice ${el.name}`, idx);
    // console.log('allChat chat', el);
    const messages = [
      {
        attachments: [],
        body: el.lastMessage?.body || '',
        contentType: 'text',
        createdAt: new Date(el?.lastMessage?.timestamp * 1000 || null).toISOString(), // '2023-06-12T07:56:20.699Z',
        id: el?.lastMessage?.id || 'abc123',
        senderId: el.lastMessage?.fromMe ? sender.id || sender.uid : el.name,
        from: el.lastMessage?.fromMe ? el.id._serialized : '',
      },
    ];

    // console.log('el :v', el);
    const participants = getParticipantsFrom0(el, sender);

    const newConversation = {
      archived: !!el.archived,
      id: el.name,
      messages,
      participants,
      type: 'ONE_TO_ONE',
      unreadCount: el.unreadCount,
      to: el.id._serialized,
      from: el.lastMessage?.fromMe ? el.id._serialized : '',
    };

    conversations.push(newConversation);
  });

  return conversations;
};

export const getNameByNumberInMinimalConversations = (minimalConversations, numberSerialized) => {
  // minimalConversations
  // console.log('minimalConversations', minimalConversations);
  const conversations = Object.values(minimalConversations.byId);
  // console.log('conversationsMinimal', conversations);

  let name = '';
  conversations.forEach((el) => {
    if (el.to === numberSerialized) {
      name = el.id;
    }
  });

  return name;
};

export const getNumberByNameInMinimalConversations = (minimalConversations, name) => {
  // minimalConversations
  const conversations = Object.values(minimalConversations.byId);
  // console.log('conversations', conversations);

  let numberSerialized = '';
  conversations.forEach((el) => {
    if (el.id === name) {
      numberSerialized = el.to;
    }
  });

  return numberSerialized;
};

export const getLastMessageSendedByRecipient = (currentConversation, senderId) => {
  // const senderId = currentConversation.to;
  let lastMessage = { createdAt: new Date('2020-01-01') };
  currentConversation.messages.forEach((message) => {
    if (message.senderId === senderId) {
      lastMessage = message;
    }
  });

  return lastMessage;
};

const handleAddPostalCode = (countries, currentSession) => {
  let code = '';
  countries.forEach((el) => {
    if (currentSession.phoneNumber.slice(0, 3) === el.numericCode) {
      code = el.numericCode;
      return code;
    }
  });
  return code;
};

export const handleSendMessageUtil = async (
  value,
  conversations,
  countries,
  currentSession,
  currentCompany,
  getCurrentConversation,
  sendMetaMessage,
  dispatch,
  sendMessage
) => {
  try {
    // let conversation = conversations.byId[value.conversationId];
    let { conversationId } = value;
    let conversation = await getCurrentConversation(conversationId, value.contentType);

    // console.log('conversations', conversations);
    // console.log('value.conversationId', value.conversationId);

    if (!conversation) {
      const {
        messageId,
        message,
        contentType,
        attachments,
        createdAt,
        senderId,
        senderName,
        senderId2,
        companyName,
        branchName,
      } = value;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
        senderName,
        senderId2,
        companyName,
        branchName,
      };

      // console.log('conversations', conversations);
      conversations.allIds.forEach((el) => {
        if (conversations.byId[el].to.includes(value.conversationId)) {
          // console.log('Encontró algo XD');
          conversation = conversations.byId[el];
          conversationId = el;
        }
      });
      if (!conversation) {
        // console.log('no encontró algo');
        // eslint-disable-next-line prefer-destructuring
        // console.log('conversationId', value.conversationId);
        if (value.conversationId.length <= 8) {
          const numericCode = handleAddPostalCode(countries, currentSession);
          conversationId = `${numericCode}${value.conversationId}`;
          // console.log('conversationIdok', conversationId);
        } else {
          conversationId = value.conversationId; // value.conversationId.includes('506') ? value.conversationId : `506${value.conversationId}`;
        }
        conversation = {
          to: conversationId,
          archived: false,
          id: conversationId,
          messages: [newMessage],
          participants: [],
          type: 'ONE_TO_ONE',
          unreadCount: 0, //  el.unreadCount
          // to: newMessage[0].id.remote,
          // from: newMessage[0]?.fromMe ? newMessage[0].id.remote : '',
        };
        // console.log('newConversationPen', conversation);
      }
    }

    const message = {
      id: currentSession.id,
      message: value.message,
      chatId: conversation.to,
      to: conversation.to,
      phoneNumberId: currentSession.phoneNumberId,
      token: currentSession.token,
    };
    // console.log('message to send', message);
    // enviar mensaje
    const response = await sendMetaMessage(message);
    if (response.status === 200) {
      const conversationObject = {
        ...value,
        messageId: response.messageId,
        conversationId,
      };

      dispatch(
        sendMessage({
          conversation,
          conversationObject,
          conversations,
          companyId: currentCompany.id,
          chatId: conversation.to,
          currentSession,
          // sender: userProfile,
          sender: currentCompany,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const handleSendFileMessageUtil = async (
  messageObject,
  currentSession,
  currentCompany,
  conversations,
  getCurrentConversation,
  sendMetaImage,
  dispatch,
  sendMessage,
  enqueueSnackbar
) => {
  // const conversation = conversations.byId[messageObject.conversationId];
  const { conversationId } = messageObject;
  const conversation = await getCurrentConversation(conversationId, messageObject.contentType);

  // console.log('conversation test', conversation);

  if (!conversation) {
    // console.log('no tiene conversacion');
    enqueueSnackbar(`Debe enviar una plantilla primero`, {
      variant: 'error',
    });
    return;
  }
  // console.log('si tiene conversacion');
  const messageToMeta = {
    id: currentSession.id,
    message: messageObject.message,
    chatId: conversation.to,
    to: conversation.to,
    phoneNumberId: currentSession.phoneNumberId,
    token: currentSession.token,
    document: {
      link: messageObject.file.urlCert, // "http(s)://the-url.pdf",
    },
  };
  // console.log('message to send', messageToMeta);
  // enviar mensaje
  const response = await sendMetaImage(messageToMeta);
  console.log('response xdxxd', response);
  if (response.status === 200) {
    const conversationObject = {
      ...messageObject,
      conversationId,
    };

    dispatch(
      sendMessage({
        conversation,
        conversationObject,
        conversations,
        companyId: currentCompany.id,
        chatId: conversation.to,
        currentSession,
        // sender: userProfile,
        sender: currentCompany,
      })
    );
  }
};

const getLastMessageTime = (conversation) => {
  const { messages } = {
    messages: [{ createdAt: new Date() }],
    ...conversation,
  };
  // console.log('conversation find', conversation);
  // console.log('messages find', messages);
  const lastMessage = messages[messages.length - 1];
  return new Date(lastMessage?.createdAt || new Date()).getTime();
};

export const getChatsToUp = (conversations, keyBy) => {
  // console.log('conversations up', conversations);
  const conversationsTemp = conversations;
  conversationsTemp.sort((a, b) => getLastMessageTime(b) - getLastMessageTime(a));

  // console.log('conversationsTemp', conversationsTemp);
  const byId = keyBy(conversationsTemp, 'id');
  const newAllIds = Object.keys(byId);
  // console.log('newAllIds', newAllIds);

  return newAllIds;
};

export const updateChatsToUp = (conversations, keyBy, dispatch, setConversationOrder) => {
  // // console.log('conversations up', conversations);
  // const conversationsTemp = conversations;
  // conversationsTemp.sort((a, b) => getLastMessageTime(b) - getLastMessageTime(a));

  // // console.log('conversationsTemp', conversationsTemp);
  // const byId = keyBy(conversationsTemp, 'id');
  // const newAllIds = Object.keys(byId);
  // // console.log('newAllIds', newAllIds);
  const newAllIds = getChatsToUp(conversations, keyBy);
  dispatch(setConversationOrder(newAllIds));
};

const mergeMessages = (conversation, firebaseConversation) => {
  const combinedJson = [...(conversation.messages || []), ...(firebaseConversation.messages || [])];

  combinedJson.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

  const uniqueJson = [];
  const ids = [];

  combinedJson.forEach((item) => {
    if (!ids.includes(item.createdAt)) {
      ids.push(item.createdAt);
      uniqueJson.push(item);
    }
  });

  return uniqueJson;
};

export const callbackConversationUtil = (
  data,
  conversations,
  dispatch,
  setConversation,
  keyBy,
  setConversationOrder
) => {
  console.log('data Actualizado Realtime', data);
  console.log('conversations in listen', conversations);
  const currentConversation = conversations.byId[data.id];
  console.log('currentConversation', currentConversation);
  const isNewChat = !conversations.allIds.includes(data.id);

  if (currentConversation || isNewChat) {
    let newData = data;

    if (currentConversation?.id) {
      newData = {
        ...data,
        participants: currentConversation.participants,
      };
    }

    const conversationsTemp = {
      ...conversations.byId,
      [newData.id]: newData,
    };
    console.log('conversationsTemp', conversationsTemp);
    updateChatsToUp(Object.values(conversationsTemp), keyBy, dispatch, setConversationOrder);
    console.log('data prueba', newData);

    const sortedMessages = mergeMessages({}, newData);
    console.log('data prueba2', newData);
    console.log('sortedMessages', sortedMessages);

    const newConversation = {
      ...newData,
      messages: sortedMessages,
    };
    console.log('newConversation', newConversation);

    dispatch(setConversation(newConversation)); // data
    // console.log('entra aquí 1');

    return { byId: conversationsTemp, allIds: Object.keys(conversationsTemp) };
  }
  return conversations;
};

export const showDate = (moment, lastActivity) => {
  // return moment(lastActivity).format("DD/MM/YYYY")

  // Obtener la fecha actual
  const today = moment().startOf('day');

  // Crear un objeto Moment a partir de lastActivity
  const activityDate = moment(lastActivity);

  // Verificar si la fecha de la actividad es igual al día de hoy
  if (activityDate.isSame(today, 'day')) {
    // Mostrar el formato de horas "HH:mm"
    const formattedTime = activityDate.format('HH:mm');
    // console.log(formattedTime);
    return formattedTime;
  }
  // Mostrar el formato de fecha "DD/MM/YYYY"
  const formattedDate = activityDate.format('DD/MM/YYYY');
  // console.log(formattedDate);
  return formattedDate;
};

export const getLastActivity = (conversation) =>
  (conversation && conversation?.messages[conversation.messages.length - 1]?.createdAt) || new Date();
