import firebase from 'firebase/app';
import { changeEmail } from '../utils/encryptText';
import { firebaseConfig } from '../config';
import { getPermissionsOfRoleByUid } from './RoleService';
import { createLog } from '../redux/slices/log';
// import { getCompany } from './CompanyService';

const usersBaseUrl = 'users';
const usersByCompanyBaseUrl = 'users_company';
const emialBaseUrl = 'emails_uid';

// export function getOwnUsers(userId) {
//   const rootRef = firebase.database().ref(`/user_users/${userId}`);
//   return rootRef;
// }

export const getAllUsers = async () =>
  firebase
    .database()
    .ref(`/${usersBaseUrl}`)
    .once('value')
    .then((snap) => snap.val());

export async function getUsersByCompany(companyId) {
  const rootRef = firebase.database().ref(`/${usersByCompanyBaseUrl}/${companyId}`);
  return rootRef;
}

export const getUserByUid = async (uid) => {
  // const { currentUser } = firebase.auth();
  const user = await firebase
    .database()
    .ref(`${usersBaseUrl}/${uid}`)
    .once('value')
    .then((snap) => snap.val());

  return user;
};

export const createUser = async (company, roleId, user) => {
  const newUserRef = await firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  // const permissions = await getPermissionsOfRoleByUid(company.id, roleId);
  // const company = await getCompany(companyId);

  const newUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    companies: {
      [company.id]: {
        name: company.name,
        role: 'pendiente',
        roleId,
        // permissions,
      },
    },
    photoURL: '/static/user/user.png',
  };

  await newUserRef.set({ ...newUser });
};

export const editUser = async (uid, user) => {
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${uid}`);
  await newUserRef.update(user);
};
export const editUserSettings = async (uid, user) => {
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${uid}/settings`);
  await newUserRef.update(user);
};

// export const addUsersInUser = async (user) => {
//   const { currentUser } = firebase.auth();
//   const rootRef = firebase.database().ref(`/user_users/${currentUser.uid}/${user.uid}`);
//   const newUser = { email: user.email, name: `${user.firstName} ${user.lastName}` };
//   await rootRef.set(newUser);
// };

export const addUsersInCompany = async (companyId, roleId, user, action) => {
  // const { currentUser } = firebase.auth();
  const rootRef = firebase.database().ref(`/${usersByCompanyBaseUrl}/${companyId}/${user.uid}`);

  const oldUserLog = [];
  const newUserLog = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
  };
  const key = `${user.uid}${companyId}`;
  const log = await createLog('user', action, key, oldUserLog, newUserLog);

  const newUser = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    roleId,
    log: { ...user?.log, [log]: true },
  };
  await rootRef.set(newUser);
};

// export const editUsersInUser = async (userId, uid, user) => {
//   const rootRef = firebase.database().ref(`/user_users/${userId}/${uid}`);
//   const newUser = { email: user.email, name: `${user.firstName} ${user.lastName}` };
//   await rootRef.update(newUser);
// };

export const editUsersInCompany = async (companyId, uid, user) => {
  // const currentUser = await getUserByUid(uid);

  const key = `${uid}${companyId}`;
  const currentUserLog = { role: '' };
  const newUserLog = { role: user.companies[companyId].roleId };
  /* const log = */ await createLog('user', 'edit', key, currentUserLog, newUserLog);

  const rootRef = firebase.database().ref(`/${usersByCompanyBaseUrl}/${companyId}/${uid}`);
  const newUser = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    roleId: user.companies[companyId].roleId,
    // log: { ...currentUser?.log, [log]: true },
  };
  await rootRef.update(newUser);
};

export const registerUser = async (user) => {
  const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
  const userAuth = await secondaryApp.auth().createUserWithEmailAndPassword(user.email, user.password);
  secondaryApp.auth().signOut();
  // secondaryApp.delete();
  return userAuth.user;
};

// export const deleteUser = async (uid, userId) => {
//   const rootRef = firebase.database().ref('/');

//   // const userRef = rootRef.child(`users/${uid}/${userId}`);
//   // userRef.remove();

//   const userRef2 = rootRef.child(`user_users/${uid}/${userId}`);
//   userRef2.remove();
// };

export const deleteUserInCompany = async (companyId, userId, needLog, name, action) => {
  // const rootRef = firebase.database().ref('/');
  // const userRef = rootRef.child(`${usersByCompanyBaseUrl}/${companyId}/${userId}`);
  // await userRef.remove();
  if (needLog) {
    const key = `${userId}${companyId}`;
    const newLog = { banished: 'true', name };
    /* const log = */ await createLog('user', action, key, {}, newLog);
  }
  const updates = {};
  updates[`${usersByCompanyBaseUrl}/${companyId}/${userId}/banished`] = true;
  await firebase.database().ref().update(updates);
};

export const addEmailUid = async (user) => {
  const changedEmail = changeEmail(user.email);
  const rootRef = firebase.database().ref(`/${emialBaseUrl}/${changedEmail}`);
  const newUser = { uid: user.uid, name: `${user.firstName} ${user.lastName}` };
  await rootRef.set(newUser);
};

export const getUidByChangedEmail = async (changedEmail) => {
  const user = await firebase
    .database()
    .ref(`${emialBaseUrl}/${changedEmail}`)
    .once('value')
    .then((snap) => snap.val());
  return user?.uid;
};

export const addCompanyToUser = async (user, roleId, company) => {
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  // const company = await getCompany(companyId);
  const permissions = await getPermissionsOfRoleByUid(user.roleId);

  const newUser = {
    ...user,
    companies: {
      ...user.companies,
      [company.id]: {
        name: company.name,
        role: 'pendiente',
        roleId,
        permissions,
      },
    },
  };

  // await newUserRef.set({ ...newUser });
  await newUserRef.update({ ...newUser });
};

export const editStatusFromUser = async (user, roleId, company, newRole) => {
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  // const company = await getCompany(companyId);
  const permissions = await getPermissionsOfRoleByUid(user.roleId);

  const newUser = {
    ...user,
    companies: {
      ...user.companies,
      [company.id]: {
        name: company.name,
        role: newRole,
        roleId,
        permissions,
      },
    },
  };

  // await newUserRef.set({ ...newUser });
  await newUserRef.update({ ...newUser });
  return newUser;
};

export const getUserPointsRating = async (uid) => {
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const pointsRating = await firebase
    .database()
    .ref(`${usersBaseUrl}/${uid}/pointsRating`)
    .once('value')
    .then((snap) => snap.val() || 0);

  return pointsRating;
};

export const editUserPointsRating = async (uid, newPointsRating) => {
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${uid}/pointsRating`);
  await newUserRef.set(newPointsRating);
};

export const getCompanyPointsRating = async (id) => {

  console.log('idcompany',id)
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const pointsRating = await firebase
    .database()
    .ref(`companies/${id}/pointsRating`)
    .once('value')
    .then((snap) => snap.val() || 0);

  return pointsRating;
};

export const editCompanyPointsRating = async (id, newPointsRating) => {
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const newUserRef = firebase.database().ref(`/companies/${id}/pointsRating`);
  await newUserRef.set(newPointsRating);
};
