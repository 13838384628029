import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CardHeader,
  Grid,
  IconButton,
  Avatar,
  useTheme,
} from '@material-ui/core';
import Switch from '@mui/material/Switch';
import { Icon } from '@iconify/react';
import moment from 'moment';
// import expandVertical from '@iconify/icons-bx/expand-vertical';
import { useSelector } from 'react-redux';
import { MCircularProgress } from '../../@material-extend';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { ProductListHead } from '../../e-commerce/product-list';
import ToolbarComponent from '../Toolbar/ToolbarComponent';
import MoreMenu from '../Menu/MoreMenu';
import Label from '../../Label';
import { fCurrency, showPrice } from '../../../utils/formatNumber';
import { switchCurrency } from '../../../utils/getCompanyToRedux';
import { getCompanyById } from '../../../_apis_/companies';

const TableList = ({
  title,
  TABLE_HEAD,
  getDocuments,
  handleDelete,
  nameInSpanish,
  nameInEnglish,
  urlToDownloadSheet,
  isMasculine,
  needMassiveCharge,
  isLoading,
  documents,
  isList,
  handleClickItem,
  tableStyle,
  dontNeedCheckBox,
  dontNeedCreateButton,
  dontNeedDuplicateButton,
  needDeleteButton = true,
  handleCreateItem,
  handleEditItem,
  handleDuplicateItem,
  needSettings,
  handleExportAll,
  needMoreMenu = true,
  needToolBar = true,
  anotherToolbarComponent,
  anotherButton,
  anotherTextButton,
  nameDocument,
  initialOrder,
  searchList,
  CustomDialog,
  InvoiceGeneral,
  LogInvoice,
  logApi,
  valuesLog,
  setOpenModal,
  openModal,
  detailsTaxInModal,
  dataValues,
  setBranchIdRow,
  setIdRow,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const { currentBranch, currentCompany } = useSelector((state) => state.company);
  const [openConfirmationDialogFromDetailsTax, setOpenConfirmationDialogFromDetailsTax] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(initialOrder || 'asc');
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [orderBy, setOrderBy] = useState(nameDocument);
  // Componente para obtener el nombre de la empresa
  const CompanyCell = ({ companyId }) => {
    const [companyName, setCompanyName] = useState(''); // Inicializa el nombre de la empresa

    useEffect(() => {
      let isMounted = true; // Variable para controlar si el componente está montado

      const fetchCompany = async () => {
        if (companyId) {
          const company = await getCompanyById(companyId); // Llamada asíncrona
          if (isMounted) {
            setCompanyName(company.companyName); // Actualiza el estado con el nombre solo si el componente está montado
          }
        }
      };

      fetchCompany();

      // Cleanup function para evitar actualización del estado en caso de que el componente se desmonte
      return () => {
        isMounted = false; // Marca el componente como desmontado
      };
    }, [companyId]);

    return <>{companyName || ''}</>; // Muestra "Cargando..." mientras obtiene el nombre
  };

  const filterDocuments = applySortFilter(
    documents,
    getComparator(order, orderBy),
    filterName,
    nameDocument,
    searchList
  );
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;
  const isProductNotFound = filterDocuments.length === 0;

  const rowStyle = {
    cursor: 'pointer',
    background: '#F4F6F8',
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);

    setSelectedId(id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const renderBalanceQuantities = (balanceQuantities, typeArticle) => {
    if (typeArticle === 'Servicio') {
      return (
        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color="info">
          N/A
        </Label>
      );
    }
    // typeArticle === 'product'
    if (balanceQuantities <= 0) {
      return (
        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color="error">
          {balanceQuantities}
        </Label>
      );
    }
    return (
      <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color="success">
        {balanceQuantities}
      </Label>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getDocuments) getDocuments();
  }, [getDocuments]);

  const removeHTMLTags = (input) => {
    const div = document.createElement('div');
    div.innerHTML = input;
    return div.textContent || div.innerText || '';
  };

  const getPathByInvoiceType = (invoiceType) => {
    if (invoiceType.includes('FDV')) {
      return 'salesInvoices';
    }
    if (invoiceType.includes('PPE')) {
      return 'salesInvoices';
    }
    if (invoiceType.includes('FDP')) {
      return 'supplierInvoices';
    }
    if (invoiceType.includes('NDC')) {
      return 'creditNotes';
    }
    if (invoiceType.includes('FRR')) {
      return 'recurrentInvoice';
    }
    if (invoiceType.includes('CPV') || invoiceType.includes('PPV')) {
      return 'budget';
    }
    return '';
  };

  const renderSwitchName = (row, el) => {
    /* Si es una fecha muestrela como tal, si es un array, normal */
    // console.log('row', row);
    //  console.log('el', el);

    if (el.id === 'balanceQuantities') {
      // exclusivo para artículos
      const balanceQuantities =
        Number(row?.creditNotes ?? 0) +
        Number(row?.supplierInvoices ?? 0) -
        Number(row?.salesInvoices ?? 0) +
        Number(row?.adjustment ?? 0);
      return renderBalanceQuantities(balanceQuantities, row?.typeArticle);
    }
    if (el.isDate) {
      return moment(row[el.id]).calendar();
    }
    if (el.isArray) {
      return row[el.isArray][el.id];
    }
    if (el.isCurrency) {
      return fCurrency(row[el.id]);
    }

    if (el.currency) {
      const currencySymbol = switchCurrency(row[el.currency]);
      // return `${currencySymbol}${row[el.id]}`;
      return showPrice(currencySymbol, row[el.id]);
    }
    if (el.currencyCompany) {
      const currencySymbol = switchCurrency(currentCompany.currency);
      // return `${currencySymbol}${row[el.id]}`;
      return showPrice(currencySymbol, row[el.id]);
    }

    if (el.anotherLabel) {
      return el.anotherLabel[row[el.id]];
    }
    if (el.statusColor) {
      return (
        <Label
          // color={(invoiceStatus === 'Aceptada' && 'success') || 'warning'}
          color={row[el.statusColor]}
          sx={{ textTransform: 'uppercase', mb: 1 }}
        >
          {row[el.id]}
        </Label>
      );
    }

    if (el.id === 'invoiceStatus') {
      // Verifica el estado de la factura
      const status = row[el.id]; // Obtiene el valor del estado
      let labelColor = 'default'; // Color por defecto

      // Define el color según el estado
      if (status === 'Rechazada') {
        labelColor = 'error'; // Rojo
      } else if (status === 'Aceptada y Timbrada') {
        labelColor = 'success'; // Verde
      }

      return (
        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={labelColor}>
          {status}
        </Label>
      );
    }

    // Si no es `invoiceStatus`, mantiene el comportamiento original
    if (el.numberLabel) {
      return (
        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={el.numberLabel}>
          {row[el.id] || 0}
        </Label>
      );
    }

    if (el.iconButton) {
      return (
        <IconButton
          disabled={(el.disabled && row[el.disabled.attr] === el.disabled.value) || false}
          onClick={() => el.iconButton.click(row)}
        >
          <Icon icon={el.iconButton.icon} />
        </IconButton>
      );
    }
    if (el.isImage) {
      return <Box component={Avatar} alt={row[el.alt]} src={row[el.isImage]} sx={{ mx: 2 }} />;
    }
    if (el.secondLabel) {
      return `${row[el.id]} ${row[el.secondLabel]}`;
    }
    if (el.needClientValidation) {
      // exclusivo para clientes
      // console.log('row.clientType', row.clientType);
      if (row.clientType === 'personal') {
        return `${row.principalContactFirstName} ${row.principalContactLastName}`;
      }
    }
    if (el.bottomLabel) {
      return (
        <div>
          <p>{row[el.id]}</p> <p style={{ fontSize: 10 }}>{row[el.bottomLabel]}</p>
        </div>
      );
    }
    if (el.moreArticleLabels) {
      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right' }}>
            <p>{row[el.id]}</p>
            {row[el.moreArticleLabels.right] && (
              <p
                style={{
                  fontSize: 12,
                  color: 'gray',
                  marginRight: 'auto',
                  marginLeft: 4,
                  display: 'flex',
                  alignIAtems: 'center',
                }}
              >{`(${row[el.moreArticleLabels.right]})`}</p>
            )}
          </div>
          {row[el.moreArticleLabels.bottom] && (
            <p style={{ fontSize: 12, color: 'gray' }}>{removeHTMLTags(row[el.moreArticleLabels.bottom])}</p>
          )}
        </div>
      );
    }
    if (el.renderSwitch) {
      return <Switch checked={!row[el.id]} onClick={(e) => el.renderSwitch(e, row)} />;
    }
    if (el.isInvoiceNumber) {
      const invoiceType = row.invoiceNumber.split('-')[0];
      return (
        <Link
          to={{
            pathname: `/dashboard/${getPathByInvoiceType(invoiceType)}/${row.id}/detail`,
          }}
        >
          {row.invoiceNumber}
        </Link>
      );
    }
    if (el.priceWithTaxes) {
      const currencySymbol = switchCurrency(currentCompany.currency);

      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right' }}>
            <p>
              {showPrice(currencySymbol, `${Number(row[el.id]) + Number(row[el.id]) * ((row.taxSaleInfo || 0) / 100)}`)}
            </p>
            {/* {row[el.moreArticleLabels.right] && (
              <p
                style={{
                  fontSize: 12,
                  color: 'gray',
                  marginRight: 'auto',
                  marginLeft: 4,
                  display: 'flex',
                  alignIAtems: 'center',
                }}
              >{`(${row[el.moreArticleLabels.right]})`}</p>
            )} */}
          </div>
          {/* {row[el.moreArticleLabels.bottom] && ( */}
          <p style={{ fontSize: 12, color: 'gray' }}>{`(${showPrice(currencySymbol, `${row[el.id]}`)} + ${
            row.taxSaleInfo || 0
          }% ${showPrice(currencySymbol, `${Number(row[el.id]) * ((row.taxSaleInfo || 0) / 100)}`)})`}</p>
          {/* )} */}
        </div>
      );
    }
    return row[el.id];
  };

  const onClickNormalItem = (row, id) => {
    if (handleClickItem) {
      handleClickItem(row);
      return;
    }
    // console.log(row);
    if (detailsTaxInModal) {
      setIdRow(row.id);
      setBranchIdRow(row.idBranch);
      setOpenConfirmationDialogFromDetailsTax(true);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/detail`);
    }
  };

  // eslint-disable-next-line arrow-body-style
  const renderNormalItem = (row, el, id) => {
    return (
      <TableCell
        key={`normal-cell-${id}-${el.id}`}
        onClick={() => {
          if (!el.iconButton) onClickNormalItem(row, id);
        }}
        align="left"
      >
        {renderSwitchName(row, el)}
      </TableCell>
    );
  };

  const validateRows = () => {
    if ((documents.length - 1) % rowsPerPage === 0 && page !== 0) {
      setPage(page - 1);
    }
  };

  // eslint-disable-next-line arrow-body-style
  const renderMoreMenu = (row, el, id) => {
    return (
      <TableCell key={`${id}-${el.id}-menu-cell`} tabIndex={0} style={{ width: 40 }} align="right">
        {needMoreMenu && (
          <MoreMenu
            id={id}
            item={row}
            handleDelete={handleDelete}
            nameInEnglish={nameInEnglish}
            nameInSpanish={nameInSpanish}
            isMasculine={isMasculine}
            handleEditItem={handleEditItem}
            handleDuplicateItem={handleDuplicateItem}
            validateRows={validateRows}
            needSettings={needSettings}
            dontNeedDuplicateButton={dontNeedDuplicateButton}
            needDeleteButton={needDeleteButton}
          />
        )}
      </TableCell>
    );
  };

  return (
    <Card sx={tableStyle}>
      {title && (
        <CardHeader
          title={
            <Grid container spacing={3}>
              <Grid justifyContent="flex-start" alignItems="center" item>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            </Grid>
          }
        />
      )}

      {needToolBar && (
        <ToolbarComponent
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          id={selected.length === 1 ? selectedId : null}
          handleDelete={handleDelete}
          setSelected={setSelected}
          name={nameInSpanish}
          isMasculine={isMasculine}
          nameInEnglish={nameInEnglish}
          needMassiveCharge={needMassiveCharge}
          urlToDownloadSheet={urlToDownloadSheet}
          dontNeedCreateButton={dontNeedCreateButton}
          dontNeedDuplicateButton={dontNeedDuplicateButton}
          handleCreateItem={handleCreateItem}
          handleEditItem={handleEditItem}
          handleDuplicateItem={handleDuplicateItem}
          anotherToolbarComponent={anotherToolbarComponent}
          anotherButton={anotherButton}
          anotherTextButton={anotherTextButton}
          handleExportAll={handleExportAll}
        />
      )}

      <Scrollbar>
        <TableContainer>
          <Table>
            <ProductListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={documents.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              dontNeedCheckBox={dontNeedCheckBox}
            />

            <TableBody>
              {filterDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id } = row;
                const isItemSelected = selected.indexOf(id) !== -1;
                const isCurrentBranch = currentBranch?.id === id; // Compara con currentBranch.id

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: isCurrentBranch ? '#ECECEC' : 'inherit', // Aplica color gris si es la sucursal actual
                      '&:hover': {
                        backgroundColor: isCurrentBranch ? '#ECECEC' : 'rgba(0, 0, 0, 0.04)', // Hover diferente para la sucursal actual
                      },
                    }}
                  >
                    {!dontNeedCheckBox && (
                      <TableCell key={`cell-${id}`} padding="checkbox" onClick={(event) => handleClick(event, id, id)}>
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                    )}
                    {TABLE_HEAD.map((el) =>
                      el.id !== '' ? renderNormalItem(row, el, id) : renderMoreMenu(row, el, id)
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }} key="emptyRows">
                  <TableCell colSpan={20} key={`cell-empty`} />
                </TableRow>
              )}
            </TableBody>
            {isProductNotFound && !isLoading && (
              <TableBody>
                <TableRow key="isProductNotFound">
                  <TableCell key={`cell-notFound`} align="center" colSpan={20}>
                    <Box sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow key="isLoading">
                  <TableCell key={`cell-isLoading`} align="center" colSpan={20}>
                    <Box sx={{ py: 3 }}>
                      <MCircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            {openModal && (
              <CustomDialog open={openModal} title={`Log Hacienda `} onHide={() => setOpenModal(false)} maxWidth="md">
                <br />
                <LogInvoice dataValues={valuesLog} logApi={logApi || []} />
              </CustomDialog>
            )}
            {openConfirmationDialogFromDetailsTax && (
              <CustomDialog
                open={openConfirmationDialogFromDetailsTax}
                title={`Detalle `}
                onHide={() => setOpenConfirmationDialogFromDetailsTax(false)}
                maxWidth="lg"
              >
                <br />
                <InvoiceGeneral dataValues={dataValues} nameInvoice={'FACTURA'} />
              </CustomDialog>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[15, 50, 100, 250]}
        component="div"
        count={filterDocuments.length} // documents
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

// -----------------------------------------------
function descendingComparator(a, b, orderBy) {
  const isDate = moment(a[orderBy], moment.ISO_8601, true).isValid();

  if (isDate) {
    const dateA = a[orderBy] ? new Date(a[orderBy]).getTime() : null;
    const dateB = b[orderBy] ? new Date(b[orderBy]).getTime() : null;

    if (dateA === null && dateB === null) return 0;
    if (dateA === null) return 1;
    if (dateB === null) return -1;

    return dateB - dateA;
  }

  // No es una fecha
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const getFlagInSearchList = (_user, query, searchList) => {
  let flag = false;
  searchList.forEach((nameDocument) => {
    let doc;
    if (nameDocument.includes('.')) {
      const obj = nameDocument.split('.');
      doc = _user[obj[0]][obj[1]];
    } else {
      doc = _user[nameDocument];
    }
    flag = flag || `${doc}`.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
  return flag;
};

function applySortFilter(array, comparator, query, nameDocument, searchList) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    // eslint-disable-next-line arrow-body-style
    return array.filter((_user) => {
      if (searchList) {
        return getFlagInSearchList(_user, query, searchList);
      }
      return _user[nameDocument]?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

TableList.propTypes = {
  title: PropTypes.string,
  TABLE_HEAD: PropTypes.array,
  getDocuments: PropTypes.func,
  handleDelete: PropTypes.func,
  nameInSpanish: PropTypes.string,
  nameInEnglish: PropTypes.string,
  urlToDownloadSheet: PropTypes.string,
  isMasculine: PropTypes.bool,
  needMassiveCharge: PropTypes.bool,
  isLoading: PropTypes.bool,
  documents: PropTypes.array,
  isList: PropTypes.string,
  handleClickItem: PropTypes.func,
  tableStyle: PropTypes.object,
  dontNeedCheckBox: PropTypes.bool,
  dontNeedCreateButton: PropTypes.bool,
  dontNeedDuplicateButton: PropTypes.bool,
  needDeleteButton: PropTypes.bool,
  handleCreateItem: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleDuplicateItem: PropTypes.func,
  needSettings: PropTypes.bool,
  handleExportAll: PropTypes.func,
  needMoreMenu: PropTypes.bool,
  needToolBar: PropTypes.bool,
  anotherToolbarComponent: PropTypes.func,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
  nameDocument: PropTypes.string,
  initialOrder: PropTypes.string,
  searchList: PropTypes.array,
};

export default TableList;
