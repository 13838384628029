import { Box, Card, Container, Tab, Tabs, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { capitalCase } from 'change-case';
import { useSelector } from 'react-redux';
import Page from '../../components/Page';
import { PATH_DASHBOARD } from '../../routes/paths';
import HeaderDashboard from '../../components/HeaderDashboard';
import { getCompanyById } from '../../services/CompanyService';
import OrganizationForm from './OrganizationForm';
import BranchSelectorTab from './Tabs/BranchSelectorTab';
// import RoleList from '../General/Roles/RoleList';
import CompanyHacienda from '../../sections/@dashboard/administration/company/CompanyHacienda';
import { CompanyLog } from '../../sections/@dashboard/administration/company';
import UsersList from './Tabs/UsersList';
import RolesList from './Tabs/RolesList';
import PaymentTab from './Tabs/PaymentTab';
import useLocales from '../../hooks/useLocales';
import { getPermissionsOfRoleByUid } from '../../services/RoleService';

const OrganizationModification = ({ id: idProps }) => {
  const { id: idParams } = useParams();

  const id = idProps || idParams;
  // console.log('firstid',selectedRow)
  const history = useHistory();
  const { myProfile } = useSelector((state) => state.user);
  // console.log('selectedRow11:', selectedRow);
  const [success, setSuccess] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [isEdit, setIsEdit] = useState(true);
  const [permissions, setPermissions] = useState({});
  const { translate } = useLocales();

  const [accountNames, setAccountNames] = useState('');

  const isOwner = myProfile.companies[id]?.role === 'owner';

  const handleChangeTab = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const PROFILE_TABS_OWNER = [
    {
      value: 'Table.button10',
      component: (
        <BranchSelectorTab
          success={success}
          setSuccess={setSuccess}
          company={selectedCompany}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          permissions={permissions}
        />
      ),
      sx: { marginLeft: '20px' },
    },
    {
      value: 'Table.button7',
      component: <CompanyHacienda />,
    },
    {
      value: 'Roles', // Add translation key for Roles
      component: <RolesList />,
    },
    {
      value: 'Table.button8',
      component: <UsersList permissions={permissions} />,
    },
    {
      value: 'Table.button9',
      component: <PaymentTab />,
    },
    {
      value: 'log.message', // Add translation key for Log
      component: <CompanyLog company={selectedCompany} />,
    },
  ];

  const PROFILE_TABS_GUEST = [
    {
      value: 'Table.button10',
      component: (
        <BranchSelectorTab
          success={success}
          setSuccess={setSuccess}
          company={selectedCompany}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          permissions={permissions}
        />
      ),
      sx: { marginLeft: '20px' },
    },
    {
      value: 'Table.button8', // Using translation key for Users
      component: <UsersList permissions={permissions} />,
    },
    {
      value: 'log.message', // Using translation key for Log
      component: <CompanyLog company={selectedCompany} />,
    },
  ];

  const PROFILE_TABS = isOwner ? PROFILE_TABS_OWNER : PROFILE_TABS_GUEST;

  const [currentTab, setCurrentTab] = useState(PROFILE_TABS[0].value);

  const isUserInCompany = () => myProfile.companies[id]?.role !== undefined;

  useEffect(() => {
    const fetchCompany = async () => {
      await getCompanyById(id).then((data) => {
        setSelectedCompany(data);
        setAccountNames(data?.accountNames || '');
      });
    };

    const fetchPermissions = async () => {
      const { roleId } = myProfile.companies[id];
      await getPermissionsOfRoleByUid(id, roleId).then((data) => {
        setPermissions(data);
      });
    };

    console.log('id', id);
    console.log('myProfile.companies', myProfile.companies);
    console.log('myProfile.companies[id]', myProfile.companies[id]);
    console.log('myProfile.companies[id]?.role', myProfile.companies[id]?.role);
    if (isUserInCompany()) {
      fetchCompany();
      fetchPermissions();
    } else {
      history.push(PATH_DASHBOARD.user.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, myProfile]);

  return (
    <Page title="Organizaciones">
      <Container>
        <HeaderDashboard
          heading={translate('Table.button6')}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'User',
              href: PATH_DASHBOARD.user.profile,
            },
            { name: selectedCompany?.companyName || '' },
          ]}
        />

        <OrganizationForm
          success={success}
          setSuccess={setSuccess}
          company={selectedCompany}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          permissions={permissions}
          accountNames={accountNames}
          setAccountNames={setAccountNames}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ mt: 2 }}>
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={handleChangeTab}
              >
                {PROFILE_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={capitalCase(translate(tab.value))}
                    sx={tab.sx}
                  />
                ))}
              </Tabs>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {PROFILE_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

OrganizationModification.propTypes = {};

export default OrganizationModification;
