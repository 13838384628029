import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Button, Typography, Box } from '@material-ui/core';
// routes
import Label from '../../Label';
import { PLAN_OPTIONS } from '../../../constants/paymentConstants';
import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../../../assets';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '493px',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
  needButton: PropTypes.bool,
};

export default function PricingPlanCard({ card, index, needButton }) {
  const { id, title, price, caption } = card || {};
  const { translate } = useLocales();
  const lists = [
    { text: `${PLAN_OPTIONS[id]?.users} ${translate('plan.button12')}`, isAvailable: true },
    { text: `${PLAN_OPTIONS[id]?.invoices} ${translate('plan.button13')}`, isAvailable: true },
    { text: `${PLAN_OPTIONS[id]?.storage} ${translate('plan.button14')}`, isAvailable: true },
  ];

  // const { currentCompany } = useSelector((state) => state.company);
  // const { userProfile } = useSelector((state) => state.user);

  // const [isCurrentPlanActive, setIsCurrentPlanActive] = useState(true);

  const getIcon = () => {
    switch (id) {
      case 'basic':
        return <PlanFreeIcon />;
      case 'business':
        return <PlanStarterIcon />;
      case 'premium':
        return <PlanPremiumIcon />;
      default:
        return <PlanFreeIcon />;
    }
  };

  const icon = getIcon();

  // useEffect(() => {
  //   const validations = async () => {
  //     const hasActiveSubscription = await validateCompanySubscribed(
  //       currentCompany?.subscription,
  //       currentCompany,
  //       userProfile
  //     );

  //     const isCurrentPlanActiveTemp = currentCompany?.subscription?.planName === card.title && hasActiveSubscription;
  //     setIsCurrentPlanActive(isCurrentPlanActiveTemp);
  //   };

  //   validations();
  // }, [currentCompany, card.title, userProfile]);

  return (
    <RootStyle>
      {index === 1 && (
        <Label
          color="info"
          sx={{
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          POPULAR
        </Label>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          $
        </Typography>

        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0 ? 'Free' : price}
        </Typography>

        <Typography
          gutterBottom
          component="span"
          variant="subtitle2"
          sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary',
          }}
        >
          /mo
        </Typography>
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {caption}
      </Typography>

      {/* <Box component="img" alt={title} src={icon} sx={{ width: 80, height: 80, mt: 3 }} /> */}

      <Box sx={{ width: 80, height: 80, mt: 3 }}>{icon}</Box>

      <Box component="ul" sx={{ my: 5, width: '110%' }}>
        {lists.map((item) => (
          <Box
            key={item.text}
            component="li"
            sx={{
              display: 'flex',
              typography: 'body2',
              alignItems: 'center',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              '&:not(:last-of-type)': { mb: 2 },
            }}
          >
            <Box component={Icon} icon={checkmarkFill} sx={{ width: 20, height: 20, mr: 1.5 }} />
            {item.text}
          </Box>
        ))}
      </Box>

      {needButton && (
        <Button
          to={`/dashboard/payment/${id}`}
          fullWidth
          variant="contained"
          // disabled={isCurrentPlanActive}
          component={RouterLink}
          sx={{ p: 1, mt: -3, width: 220 }}
        >
          {`${translate('plan.button15')} plan ${title}`}
        </Button>
      )}
    </RootStyle>
  );
}
